var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useContext } from "react";
import { Build } from "../../classes";
import { context } from '../../hooks';
import { Actions } from "../../declarations";
import { Spinner, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BiX } from 'react-icons/bi';
export var List = function (props) {
    var _a;
    var _b = useContext(context.buildContext), selected = _b.selected, dispatch = _b.dispatch;
    var auth = useContext(context.authContext).auth;
    var _c = useState(''), buildName = _c[0], setBuildName = _c[1];
    var _d = useState(false), newBuild = _d[0], setNewBuild = _d[1];
    var nameChange = function (e) {
        setBuildName(e.target.value);
    };
    var addBuild = function (e) {
        e.preventDefault();
        props.newBuild(buildName).then(function () {
            setNewBuild(false);
            setBuildName('');
        });
    };
    var editName = function (e, buildId) {
        e.preventDefault();
        var _a = findBuild(buildId), found = _a[0], i = _a[1];
        found.buildName = buildName;
        var newArr = __spreadArray([], props.builds, true);
        newArr[i] = Build.clone(found);
        props.setBuilds(newArr);
        if (selected.buildId === buildId) {
            dispatch({ type: Actions.ChangeName, newName: buildName });
        }
        setBuildName('');
    };
    var NameForm = function (isNew, buildId) {
        var _a, _b;
        var prevName = (_b = (_a = props.builds.find(function (b) { return b.buildId === buildId; })) === null || _a === void 0 ? void 0 : _a.buildName) !== null && _b !== void 0 ? _b : null;
        if (!isNew && !buildName)
            setBuildName(prevName);
        return (React.createElement(Form, { onBlur: function () {
                setBuildName('');
                setNewBuild(false);
            }, onSubmit: isNew ? addBuild : function (e) { return editName(e, buildId); } },
            React.createElement("input", { className: 'input', placeholder: isNew ? 'Build Name' : prevName, autoFocus: true, value: buildName, onChange: nameChange, onBlur: function () {
                    setBuildName('');
                    setNewBuild(false);
                } })));
    };
    var selectBuild = function (build) {
        dispatch({ type: Actions.Swap, build: build });
    };
    var deleteBuild = function (build) {
        build.delete(auth.token).then(function (res) {
            if (res) {
                var _a = findBuild(build.buildId), i = _a[1];
                var newArr = __spreadArray([], props.builds, true);
                newArr.splice(i, 1);
                props.setBuilds(newArr);
                if (newArr.length === 0) {
                    dispatch({ type: Actions.Swap, build: null });
                    return;
                }
                if (build.buildId === selected.buildId) {
                    var newSelected = newArr.length
                        ? newArr[i]
                            ? newArr[i]
                            : newArr[i - 1]
                                ? newArr[i - 1]
                                : newArr[i + 1]
                                    ? newArr[i + 1]
                                    : null
                        : null;
                    console.log('newSelected build: ', newSelected);
                    dispatch({ type: Actions.Swap, build: newSelected });
                }
            }
            else {
                toast('Error deleting build');
            }
        });
    };
    var findBuild = function (buildId) {
        var found = props.builds.find(function (b) { return b.buildId === buildId; });
        var i = props.builds.indexOf(found);
        return [found, i];
    };
    var newBuildLi = (React.createElement("span", { className: "new-build" }, "New Build"));
    if (props.loading) {
        return (React.createElement(Spinner, { animation: 'border', className: "list-spinner" }));
    }
    else {
        return (React.createElement("div", { className: 'build-ul' },
            props.builds
                ? props.builds.map(function (build, i) { return (React.createElement("div", { key: i, onClick: function () {
                        //selectBuild(build)
                    }, className: 'build-li ' +
                        (selected
                            ? selected.buildId === build.buildId
                                ? 'selected-build'
                                : null
                            : null) },
                    React.createElement("span", { onClick: function () { return selectBuild(build); } }, build.buildName),
                    React.createElement(BiX, { onClick: function () { return deleteBuild(build); }, color: 'red' }))); })
                : null,
            props.authed ? (React.createElement("div", { key: (_a = props.builds.length + 1) !== null && _a !== void 0 ? _a : -1, onClick: function () {
                    setNewBuild(true);
                    setBuildName('');
                }, className: 'build-li' }, newBuild ? NameForm(true) : newBuildLi)) : null));
    }
};
