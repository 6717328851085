var statData = [
    {
        statId: 0,
        statName: 'armor',
        alias: null,
    },
    {
        statId: 1,
        statName: 'armorPenetration',
        alias: 'armor_penetration',
    },
    {
        statId: 2,
        statName: 'attackDamage',
        alias: 'attack_damage',
    },
    {
        statId: 3,
        statName: 'attackSpeed',
        alias: 'attack_speed',
    },
    {
        statId: 5,
        statName: 'criticalStrikeChance',
        alias: 'critical_strike_chance',
    },
    {
        statId: 6,
        statName: 'goldPer_10',
        alias: 'gold_per_10',
    },
    {
        statId: 7,
        statName: 'healAndShieldPower',
        alias: 'heal_and_shield_power',
    },
    {
        statId: 8,
        statName: 'health',
        alias: null,
    },
    {
        statId: 9,
        statName: 'healthRegen',
        alias: 'health_regen',
    },
    {
        statId: 10,
        statName: 'lethality',
        alias: null,
    },
    {
        statId: 11,
        statName: 'lifesteal',
        alias: 'life_steal',
    },
    {
        statId: 12,
        statName: 'magicPenetration',
        alias: 'magic_penetration',
    },
    {
        statId: 13,
        statName: 'magicResistance',
        alias: 'magic_resistance',
    },
    {
        statId: 14,
        statName: 'mana',
        alias: null,
    },
    {
        statId: 15,
        statName: 'manaRegen',
        alias: 'mana_regen',
    },
    {
        statId: 16,
        statName: 'movespeed',
        alias: null,
    },
    {
        statId: 17,
        statName: 'abilityHaste',
        alias: 'ability_haste;cooldown_reduction;cooldownReduction',
    },
    {
        statId: 18,
        statName: 'omnivamp',
        alias: null,
    },
    {
        statId: 19,
        statName: 'tenacity',
        alias: null,
    },
    {
        statId: 20,
        statName: 'abilityPower',
        alias: 'ability_power',
    },
    {
        statId: 21,
        statName: 'criticalStrikeDamage',
        alias: 'critical_strike_damage',
    },
    {
        statId: 22,
        statName: 'criticalStrikeDamageModifier',
        alias: 'critical_strike_damage_modifier',
    },
    {
        statId: 23,
        statName: 'attackSpeedRatio',
        alias: 'attack_speed_ratio',
    },
    {
        statId: 24,
        statName: 'attackCastTime',
        alias: 'attack_cast_time',
    },
    {
        statId: 25,
        statName: 'attackTotalTime',
        alias: 'attack_total_time',
    },
];
var Stat = /** @class */ (function () {
    function Stat(stats) {
        if (stats.length === 0) {
            return;
        }
        var _loop_1 = function (stat) {
            var name_1 = statData.find(function (prop) { return prop.statId === stat.statId; }).statName;
            var mods = {
                flat: stat.flat,
                percent: stat.percent,
                perLevel: stat.perLevel,
            };
            switch (name_1) {
                case 'abilityPower':
                    this_1.AP = mods;
                    break;
                case 'armor':
                    this_1.armor = mods;
                    break;
                case 'armorPenetration':
                    this_1.armorPen = mods;
                    break;
                case 'attackDamage':
                    this_1.AD = mods;
                    break;
                case 'attackSpeed':
                    this_1.AS = mods;
                    break;
                case 'abilityHaste':
                    this_1.AH = mods;
                    break;
                case 'criticalStrikeChance':
                    this_1.critChance = mods;
                    break;
                case 'healAndShieldPower':
                    this_1.HSP = mods;
                    break;
                case 'health':
                    this_1.health = mods;
                    break;
                case 'healthRegen':
                    this_1.HR = mods;
                    break;
                case 'lethality':
                    this_1.lethality = mods;
                    break;
                case 'lifesteal':
                    this_1.LS = mods;
                    break;
                case 'magicPenetration':
                    this_1.MP = mods;
                    break;
                case 'magicResistance':
                    this_1.MR = mods;
                    break;
                case 'mana':
                    this_1.mana = mods;
                    break;
                case 'manaRegen':
                    this_1.manaRegen = mods;
                    break;
                case 'movespeed':
                    this_1.MS = mods;
                    break;
                case 'omnivamp':
                    this_1.OV = mods;
                    break;
                case 'tenacity':
                    this_1.tenacity = mods;
                    break;
                default:
                    break;
            }
        };
        var this_1 = this;
        for (var _i = 0, stats_1 = stats; _i < stats_1.length; _i++) {
            var stat = stats_1[_i];
            _loop_1(stat);
        }
    }
    Stat._AP = 'abilityPower';
    Stat._armor = 'armor';
    Stat._armorPen = 'armorPenetration';
    Stat._AD = 'attackDamage';
    Stat._AS = 'attackSpeed';
    Stat._AH = 'abilityHaste';
    Stat._critChance = 'criticalStrikeChance';
    Stat._HSP = 'healAndShieldPower';
    Stat._health = 'health';
    Stat._HR = 'healthRegen';
    Stat._lethality = 'lethality';
    Stat._LS = 'lifesteal';
    Stat._MP = 'magicPenetration';
    Stat._MR = 'magicResistance';
    Stat._mana = 'mana';
    Stat._manaRegen = 'manaRegen';
    Stat._MS = 'movespeed';
    Stat._OV = 'omnivamp';
    Stat._tenacity = 'tenacity';
    return Stat;
}());
export { Stat };
