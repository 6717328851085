var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { patchBuild, createBuild, deleteBuild } from "../api/builds";
import { getChampStats, getItemStats } from "../api/info";
import { Actions } from "../declarations";
var Build = /** @class */ (function () {
    function Build(info, champC, itemC, champ, items, build) {
        var _a;
        if (build) {
            this.buildName = build.buildName;
            this.champ = build.champ;
            this.items = build.items;
            this.buildId = build.buildId;
            this.saved = build.saved;
            this.previousInfo = this.getBuildInfo();
            this.hash = this.currentHash();
            return;
        }
        this.buildName = info.buildName;
        this.champ = champ
            ? champ
            : champC.addChampStats(info.champId, info.champStats);
        this.items = items
            ? items
            : (_a = info.items.map(function (item) {
                return itemC.addItemStats(item.itemId, item.statsArray);
            })) !== null && _a !== void 0 ? _a : [];
        this.buildId = info.buildId;
        this.previousInfo = this.getBuildInfo();
    }
    Build.create = function (buildName, champ, champC, itemC, items) {
        return __awaiter(this, void 0, void 0, function () {
            var addItems, addChamp, itemStats, champStats, info, newBuild;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        addItems = items;
                        addChamp = champ;
                        if (!items) return [3 /*break*/, 2];
                        return [4 /*yield*/, getItemStats(items.map(function (item) { return item.itemId; }))];
                    case 1:
                        itemStats = _a.sent();
                        if (itemStats) {
                            addItems = itemStats.map(function (stat) {
                                return itemC.addItemStats(stat.itemId, stat.stats);
                            });
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, getChampStats(champ.champId)];
                    case 3:
                        champStats = _a.sent();
                        if (champStats)
                            addChamp = champC.addChampStats(champ.champId, champStats);
                        info = {
                            buildName: buildName,
                            champId: addChamp.champId,
                            champStats: [],
                            items: [],
                        };
                        newBuild = new Build(info, champC, itemC, addChamp, addItems);
                        return [2 /*return*/, newBuild];
                }
            });
        });
    };
    Build.prototype.changeChamp = function (newChamp, champC) {
        return __awaiter(this, void 0, void 0, function () {
            var stats;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!newChamp.hasStats) return [3 /*break*/, 1];
                        this.champ = newChamp;
                        return [2 /*return*/, this];
                    case 1: return [4 /*yield*/, getChampStats(newChamp.champId)];
                    case 2:
                        stats = _a.sent();
                        this.champ = champC.addChampStats(newChamp.champId, stats);
                        return [2 /*return*/, this];
                }
            });
        });
    };
    Build.prototype.save = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, newBuildInfo;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.buildId) return [3 /*break*/, 2];
                        if (!this.needSave())
                            return [2 /*return*/, this];
                        _a = this.verifyResponse;
                        return [4 /*yield*/, patchBuild(token, this)];
                    case 1:
                        if (_a.apply(this, [_b.sent()]))
                            return [2 /*return*/, this.wasSaved()];
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, createBuild(token, this)];
                    case 3:
                        newBuildInfo = _b.sent();
                        if (newBuildInfo) {
                            this.buildId = newBuildInfo.buildId;
                            return [2 /*return*/, this.wasSaved()];
                        }
                        else {
                            throw new Error('Failed to save build');
                        }
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Build.prototype.delete = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, deleteBuild(token, this)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Build.prototype.toObject = function () {
        return {
            buildId: this.buildId,
            champId: this.champ.champId,
            items: this.items.map(function (item) { return item.itemId; }),
            buildName: this.buildName,
        };
    };
    Build.prototype.verifyResponse = function (res) {
        return this.needSave(res);
    };
    Build.fromChamp = function (champ, champC, itemC) {
        return __awaiter(this, void 0, void 0, function () {
            var items, info, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        items = [];
                        _b = {
                            champId: champ.champId
                        };
                        if (!champ.hasStats) return [3 /*break*/, 1];
                        _a = champ.statsArr;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, getChampStats(champ.champId)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        info = (_b.champStats = _a,
                            _b.items = items,
                            _b);
                        return [2 /*return*/, new Build(info, champC, itemC, champ)];
                }
            });
        });
    };
    Build.prototype.getBuildInfo = function () {
        var itemInfos = this.items.map(function (item) { return ({
            itemId: item.itemId,
            from: item.from,
            into: item.into,
            statsArray: item.statsArray,
            itemName: item.itemName,
            icon: item.icon,
            isNull: false
        }); });
        return {
            buildName: this.buildName,
            buildId: this.buildId,
            champId: this.champ.champId,
            champStats: this.champ.statsArr,
            items: itemInfos
        };
    };
    Build.prototype.needSave = function (info) {
        return !this.saved;
        // const current = info ? info : this.getBuildInfo()
        // return (this.previousInfo.buildName !== current.buildName)
        //     || (this.previousInfo.champId !== current.champId)
        //     || (this.previousInfo.items.length !== current.items.length)
        //     || (current.items.some((currentItem, i) => currentItem.itemId !== this.previousInfo.items[i].itemId))
    };
    Build.clone = function (build) {
        var old = build;
        var n = new Build(undefined, undefined, undefined, undefined, undefined, build);
        n.previousInfo = old.previousInfo;
        return n;
    };
    Build.prototype.wasSaved = function () {
        this.previousInfo = this.getBuildInfo();
        this.saved = true;
        return this;
    };
    Build.prototype.reduce = function (action) {
        switch (action.type) {
            case Actions.ChangeName:
                this.buildName = action.newName;
                return this;
            case Actions.AddBuildId:
                this.buildId = action.buildId;
                return this;
            case Actions.ChangeChamp:
                this.champ = action.newChamp;
                return this;
            case Actions.PushItem:
                if (this.items.length >= 6)
                    return this;
                this.items.push(action.item);
                return this;
            case Actions.MoveItem:
                var currentPos = this.items.findIndex(function (i) { return i.itemId === action.payload.itemId; });
                if (currentPos === -1)
                    return this;
                if (this.items[action.payload.newPosition]) {
                    var temp = this.items[action.payload.newPosition];
                    this.items[action.payload.newPosition] = this.items[currentPos];
                    this.items[currentPos] = temp;
                    return this;
                }
                else {
                    if (action.payload.newPosition >= this.items.length) {
                        var newArray_1 = new Array(action.payload.newPosition + 1).fill(null);
                        this.items.forEach(function (item, i) { return newArray_1[i] = item; });
                        newArray_1[currentPos] = null;
                        newArray_1[action.payload.newPosition] = this.items[currentPos];
                        console.log('newArray: ');
                        console.log(newArray_1);
                        this.items = newArray_1;
                        return this;
                    }
                    this.items[action.payload.newPosition] = this.items[currentPos];
                    return this;
                }
            case Actions.PopItem:
                if (isPopById(action.payload)) {
                    this.items = this.items.filter(function (i) { return i.itemId !== action.payload.itemId; });
                }
                else if (isPopByPos(action.payload)) {
                    this.items.splice(action.payload.position, 1);
                }
                return this;
            case Actions.Swap:
                return Build.clone(action.build);
            default:
                return this;
        }
    };
    Build.prototype.currentHash = function () {
        return hash(JSON.stringify(this.getBuildInfo()));
    };
    Build.prototype.updateHash = function () {
        this.hash = this.currentHash();
        return this;
    };
    return Build;
}());
export { Build };
var isPopById = function (payload) { return !!payload.itemId; };
var isPopByPos = function (payload) { return !!payload.position || payload.position === 0; };
function hash(input) {
    var hash = 0, i, chr;
    if (input.length === 0)
        return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
