import React, { useContext } from 'react';
import { context } from '../../hooks';
export var LiRender = function (li) {
    var c = useContext(context.champContext);
    var i = useContext(context.itemContext);
    var listItem = li.isChamp
        ? c.champs.find(function (c) { return c.champId === li.value; })
        : i.items.find(function (i) { return i.itemId === li.value; });
    return (React.createElement("div", { className: 'champ-li' },
        React.createElement("img", { src: listItem.icon, className: 'small-icon' }),
        React.createElement("span", { className: 'small-name' }, li.label)));
};
