import React, { useState, useEffect, useContext } from "react";
import { context } from '../../hooks';
import { ItemSelector, Spinner } from "../../components";
import { OneBuild, Slot, ChampSelector } from "..";
var genJsx = function (item, i) { return React.createElement(Slot, { i: i, key: item.itemId + 1, item: item }); };
export var Builder = function () {
    var _a;
    var champs = useContext(context.champContext);
    var items = useContext(context.itemContext);
    var auth = useContext(context.authContext);
    var _b = useContext(context.buildContext), selectedBuild = _b.selected, dispatch = _b.dispatch;
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(null), itemList = _d[0], setItemList = _d[1];
    var _e = useState(null), item = _e[0], setItem = _e[1];
    useEffect(function () {
        if (!champs.champs.length || !items.items.length || !loading) {
            return;
        }
        else {
            setLoading(false);
            // setChampList(champs.champs)
            setItemList(items.items);
        }
    }, [champs.champs, items.items]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Spinner, { center: true, show: loading }),
        React.createElement("span", { className: "build-title" }, selectedBuild ? (_a = selectedBuild.buildName) !== null && _a !== void 0 ? _a : null : null),
        React.createElement(ChampSelector, null),
        React.createElement(OneBuild, null),
        React.createElement(ItemSelector, null)));
};
