import { useContext } from "react";
import { context } from "../hooks";
import { ItemActions, Actions } from '../declarations';
export var useBuild = function () {
    var _a, _b;
    var _c = useContext(context.buildContext), selected = _c.selected, dispatch = _c.dispatch;
    var auth = useContext(context.authContext).auth;
    var patchItems = function (item, action) {
        console.log(item, action);
        switch (action) {
            case ItemActions.Delete:
                console.log('deleting item: ', toPos(item.i));
                dispatch({
                    type: Actions.PopItem,
                    payload: { position: toPos(item.i) },
                });
                break;
            case ItemActions.Left:
                dispatch({
                    type: Actions.MoveItem,
                    payload: {
                        itemId: item.item.itemId,
                        newPosition: toPos(item.i - 1),
                    }
                });
                break;
            case ItemActions.Right:
                dispatch({
                    type: Actions.MoveItem,
                    payload: {
                        itemId: item.item.itemId,
                        newPosition: toPos(item.i + 1),
                    }
                });
                break;
            default:
                console.error('no action specified');
                break;
        }
        dispatch({ type: Actions.Swap, build: selected });
        if (auth.loggedIn) {
            selected.save(auth.token);
        }
    };
    return [patchItems, (_b = (_a = selected === null || selected === void 0 ? void 0 : selected.items) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0];
};
var toPos = function (n) {
    return n;
};
