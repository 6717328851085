var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useReducer } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Nav, Builder, List as BuildList } from './components';
import { Build } from './classes';
import { getChamps, getItems, getAllBuilds } from './api';
import { Col, Container, Row } from 'react-bootstrap';
import { context, reducer } from './hooks';
import { Actions } from './declarations';
export var initialContext = {
    loggedIn: false,
    email: null,
    token: null,
};
var App = function () {
    var _a = useState(initialContext), auth = _a[0], setAuth = _a[1];
    var _b = useState([]), champs = _b[0], setChamps = _b[1];
    var _c = useState([]), items = _c[0], setItems = _c[1];
    var _d = useState([]), builds = _d[0], setBuilds = _d[1];
    var _e = useState(false), loaded = _e[0], setLoaded = _e[1];
    var _f = useReducer(reducer, null), build = _f[0], dispatch = _f[1];
    var _g = useState(null), selectedChamp = _g[0], setSelectedChamp = _g[1];
    var _h = useState(false), refresh = _h[0], setRefresh = _h[1];
    var _j = useState(true), loading = _j[0], setLoading = _j[1];
    // item & champ state setters & getters
    var itemNames = function () { return items.map(function (item) { return item.itemName; }); };
    var itemIds = function () { return items.map(function (item) { return item.itemId; }); };
    var champNames = function () { return champs.map(function (champ) { return champ.champName; }); };
    var champIds = function () { return champs.map(function (champ) { return champ.champId; }); };
    var addChampStats = function (champ, stats) {
        if (!champs.length)
            return;
        var newArr = __spreadArray([], champs, true);
        var i = newArr.findIndex(function (ele) { return ele.champId === champ; });
        var newChamp = newArr[i].addStats(stats);
        newArr[i] = newChamp;
        setChamps(newArr);
        return newChamp;
    };
    var addItemStats = function (item, stats) {
        if (!items.length)
            return;
        var newArr = __spreadArray([], items, true);
        var i = newArr.findIndex(function (ele) { return ele.itemId === item; });
        var newItem = newArr[i].addStats(stats);
        setItems(newArr);
        return newItem;
    };
    var champC = { champs: champs, addChampStats: addChampStats, champIds: champIds, champNames: champNames, selectedChamp: selectedChamp, setSelectedChamp: setSelectedChamp };
    var itemC = { items: items, addItemStats: addItemStats, itemIds: itemIds, itemNames: itemNames };
    var newBuild = function (name) { return __awaiter(void 0, void 0, void 0, function () {
        var newBuild, saved;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedChamp) {
                        toast('Select a champ to create a build');
                        return [2 /*return*/];
                    }
                    newBuild = new Build({ items: [], buildName: name, champId: selectedChamp.champId, champStats: [] }, champC, itemC, selectedChamp, []);
                    if (!auth.loggedIn) return [3 /*break*/, 2];
                    return [4 /*yield*/, newBuild.save(auth.token)];
                case 1:
                    saved = _a.sent();
                    refreshBuilds(saved.buildId);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var refreshBuilds = function (selected) {
        if (!auth.loggedIn)
            return;
        setLoading(true);
        getAllBuilds(auth.token).then(function (infos) {
            var newBuilds = infos.map(function (info) { return new Build(info, champC, itemC); });
            setBuilds(newBuilds);
        }).then(function () {
            if (selected) {
                var found = builds.find(function (build) { return build.buildId === selected; });
                if (!found)
                    toast('Unknown error creating build');
                else {
                    toast("Created build ".concat(found.buildName));
                    dispatch({ type: Actions.Swap, build: found });
                }
            }
            setLoading(false);
        });
    };
    var loadedAll = function () {
        setLoaded(true);
    };
    // useEffect(() => {
    //     if (!build) return
    //     const s = build.needSave()
    //     if (!s || !auth.loggedIn) return
    //     build.save(auth.token).then(b => {
    //         console.log('saved build: ')
    //         console.log(b)
    //         dispatch({type: Actions.Swap, build: b}
    //             )})
    // }, [build])
    useEffect(function () {
        if (!build) {
            if (selectedChamp)
                setSelectedChamp(null);
            return;
        }
        var listIndex = builds.findIndex(function (b) { return b.buildId === build.buildId; });
        if (listIndex === -1) {
            console.error('build not in list');
        }
        else {
            build.updateHash();
            builds[listIndex].updateHash();
            if (build.hash !== builds[listIndex].hash) {
                var newArray = __spreadArray([], builds, true);
                newArray[listIndex] = build;
                setBuilds(newArray);
            }
        }
        if (build.champ.champId !== (selectedChamp === null || selectedChamp === void 0 ? void 0 : selectedChamp.champId))
            setSelectedChamp(build.champ);
    }, [build]);
    useEffect(function () {
        getChamps().then(setChamps).then(getItems).then(setItems).then(loadedAll);
        var savedAuth = window.localStorage.getItem('auth');
        if (savedAuth) {
            var authData = JSON.parse(savedAuth);
            setAuth(authData);
            toast("Welcome back, ".concat(authData.email));
        }
    }, []);
    useEffect(function () {
        if (!auth.loggedIn || !champs.length || !items.length || !loaded) {
            setBuilds([]);
        }
        else {
            refreshBuilds();
        }
    }, [loaded]);
    useEffect(function () {
        if (!auth.loggedIn) {
            setBuilds([]);
            setSelectedChamp(null);
            dispatch({ type: Actions.Swap, build: null });
        }
        else {
            refreshBuilds();
        }
    }, [auth.loggedIn]);
    useEffect(function () {
        if (!builds || !builds.length || build)
            return;
        dispatch({ type: Actions.Swap, build: builds[0] });
    }, [builds]);
    return (React.createElement(context.authContext.Provider, { value: { auth: auth, setAuth: setAuth } },
        React.createElement(context.champContext.Provider, { value: champC },
            React.createElement(context.itemContext.Provider, { value: itemC },
                React.createElement(ToastContainer, null),
                React.createElement(Nav, null),
                auth.loggedIn ? (React.createElement(Container, { fluid: true },
                    React.createElement(Row, null,
                        React.createElement(context.buildContext.Provider, { value: { selected: build, dispatch: dispatch } },
                            auth.loggedIn ? (React.createElement(Col, { className: 'overflow-visible', xs: 12, md: 3 },
                                React.createElement(BuildList, { authed: auth.loggedIn, loading: loading, newBuild: newBuild, builds: builds, setBuilds: setBuilds }))) : null,
                            React.createElement(Col, { className: 'main-col', xs: 12, md: auth.loggedIn ? 9 : 12 },
                                React.createElement(Builder, null)))))) : null))));
};
export default App;
