export var Actions;
(function (Actions) {
    Actions["ChangeName"] = "CHANGE_NAME";
    Actions["ChangeChamp"] = "CHANGE_CHAMP";
    Actions["PushItem"] = "PUSH_ITEM";
    Actions["MoveItem"] = "MOVE_ITEM";
    Actions["PopItem"] = "POP_ITEM";
    Actions["AddBuildId"] = "ADD_BUILD_ID";
    Actions["Swap"] = "SWAP";
})(Actions || (Actions = {}));
export var ItemActions;
(function (ItemActions) {
    ItemActions["Left"] = "MOVE_LEFT";
    ItemActions["Right"] = "MOVE_RIGHT";
    ItemActions["Delete"] = "DELETE";
})(ItemActions || (ItemActions = {}));
