var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import call from "./caller";
export var login = function (info) { return __awaiter(void 0, void 0, void 0, function () {
    var result, req, _a, res, data, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                result = {
                    loggedIn: false,
                    email: null,
                    token: null,
                    message: 'Unknown error'
                };
                _c.label = 1;
            case 1:
                _c.trys.push([1, 10, , 11]);
                return [4 /*yield*/, call('/auth/signin', 'POST', info)];
            case 2:
                req = _c.sent();
                _a = req.status;
                switch (_a) {
                    case 404: return [3 /*break*/, 3];
                    case 401: return [3 /*break*/, 4];
                    case 500: return [3 /*break*/, 5];
                    case 200: return [3 /*break*/, 6];
                }
                return [3 /*break*/, 8];
            case 3: return [2 /*return*/, __assign(__assign({}, result), { message: 'User not found' })];
            case 4: return [2 /*return*/, __assign(__assign({}, result), { message: 'Incorrect password' })];
            case 5: return [2 /*return*/, __assign(__assign({}, result), { message: 'Unknown server error' })];
            case 6: return [4 /*yield*/, req.json()];
            case 7:
                res = _c.sent();
                data = res.data;
                return [2 /*return*/, {
                        loggedIn: true,
                        email: info.email,
                        token: data.token,
                        message: res.message,
                    }];
            case 8: return [2 /*return*/, __assign(__assign({}, result), { message: 'Connection error' })];
            case 9: return [3 /*break*/, 11];
            case 10:
                _b = _c.sent();
                return [2 /*return*/, result];
            case 11: return [2 /*return*/];
        }
    });
}); };
export var changePassword = function (info) { return __awaiter(void 0, void 0, void 0, function () {
    var res, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, call('/changepass', 'PATCH', info)];
            case 1:
                res = _b.sent();
                return [2 /*return*/, res.status === 200];
            case 2:
                _a = _b.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var signOut = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call('/signout', 'GET', { token: token })];
            case 1:
                res = _a.sent();
                return [2 /*return*/, true];
        }
    });
}); };
export var signUp = function (info) { return __awaiter(void 0, void 0, void 0, function () {
    var res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call('/auth/signup', 'POST', info)];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.status === 201];
        }
    });
}); };
