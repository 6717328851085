import apiUrl from '../config';
var call = function (endpoint, method, info) {
    if (method === 'GET') {
        if (info === null || info === void 0 ? void 0 : info.token) {
            return fetch(apiUrl + endpoint, {
                headers: { JWT: info.token },
            });
        }
        else {
            return fetch(apiUrl + endpoint);
        }
    }
    var headers = {
        'Content-Type': info ? 'application/json' : null,
        JWT: (info === null || info === void 0 ? void 0 : info.token) ? info.token : null,
    };
    return fetch(apiUrl + endpoint, {
        method: method,
        headers: headers,
        body: info ? JSON.stringify(info) : null,
    });
};
export default call;
