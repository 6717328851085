var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Stat } from './stats';
var Champ = /** @class */ (function (_super) {
    __extends(Champ, _super);
    function Champ(info, stats) {
        var _this = _super.call(this, stats || []) || this;
        _this.champId = info.champId;
        _this.champName = info.champName;
        _this.icon = info.icon;
        _this.resourceType = info.resourceType;
        _this.hasStats = (stats !== undefined);
        if (_this.hasStats)
            _this.statsArr = stats;
        return _this;
    }
    Champ.prototype.addStats = function (stats) {
        var info = {
            champId: this.champId,
            champName: this.champName,
            title: this.title,
            icon: this.icon,
            resourceType: this.resourceType
        };
        return new Champ(info, stats);
    };
    return Champ;
}(Stat));
export { Champ };
