import React from "react";
import { useBuild } from "../../hooks";
import { ItemActions } from "../../declarations";
import { BiArrowBack, BiX } from 'react-icons/bi';
export var Slot = function (props) {
    var _a = useBuild(), patcher = _a[0], len = _a[1];
    // console.log(`slot ${props.i}: `)
    // console.log(props.item)
    return (React.createElement("div", { className: 'slot' }, props.item ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'top' },
            React.createElement(BiX, { className: 'delete-item', color: 'red', onClick: function () { return patcher(props, ItemActions.Delete); } }),
            React.createElement("img", { className: 'large-icon', src: props.item.icon })),
        React.createElement("div", { className: 'arrows' },
            props.i === 0 ? null : React.createElement(BiArrowBack, { onClick: function () { return patcher(props, ItemActions.Left); } }),
            props.i === len - 1 ? null : React.createElement(BiArrowBack, { onClick: function () { return patcher(props, ItemActions.Right); }, className: 'right' })))) : null));
};
