var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useState } from "react";
import { signOut as apiOut, signUp, changePassword, login as apiIn } from '../../api';
import { toast } from "react-toastify";
import { context } from "../../hooks";
import { initialContext } from "../../App";
import { Button, Navbar, Modal, Form, CloseButton, } from 'react-bootstrap';
export var Nav = function (_a) {
    var toggle = _a.toggle;
    var _b = useContext(context.authContext), auth = _b.auth, setAuth = _b.setAuth;
    var loggedIn = auth.loggedIn, token = auth.token, email = auth.email;
    var _c = useState(''), oldPass = _c[0], setOldPass = _c[1];
    var _d = useState(''), newPass = _d[0], setNewPass = _d[1];
    var _e = useState(''), confirm = _e[0], setConfirm = _e[1];
    var _f = useState(''), newEmail = _f[0], setEmail = _f[1];
    var _g = useState(true), staySignedIn = _g[0], setStaySignedIn = _g[1];
    var _h = useState(false), show = _h[0], setShow = _h[1];
    var _j = useState(false), changePass = _j[0], setChangePass = _j[1];
    var _k = useState(true), signIn = _k[0], setSignIn = _k[1];
    var _l = useState(loggedIn ? email : signIn ? 'Sign In' : 'Sign Up'), title = _l[0], setTitle = _l[1];
    useEffect(function () {
        setTitle(loggedIn ? email : signIn ? 'Sign In' : 'Sign Up');
    }, [loggedIn, signIn]);
    var toggleAuth = function () {
        setSignIn(!signIn);
    };
    var signOut = function () {
        apiOut(token);
        toast("Goodbye, ".concat(email));
        setAuth(initialContext);
        setEmail('');
        reset();
        setShow(false);
        setSignIn(true);
        window.localStorage.removeItem('auth');
    };
    var recursiveCheck = function (element, target) {
        if (element === target) {
            return true;
        }
        var children = element === null || element === void 0 ? void 0 : element.children;
        if (!children || children.length === 0) {
            return false;
        }
        else {
            var array = [];
            for (var i in children) {
                if (children[i] === target) {
                    return true;
                }
                else {
                    array.push(recursiveCheck(children[i], target));
                }
            }
            return array.some(function (element) { return element; });
        }
    };
    var onClick = function (event) {
        var content = document.getElementsByClassName('modal-content')[0];
        var clicked = event.target;
        if (recursiveCheck(content, clicked)) {
            return;
        }
        setShow(false);
        removeEventListener('click', onClick);
    };
    var closeButton = function () {
        removeEventListener('click', onClick);
        setShow(false);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var form, res, res, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    toast.dismiss();
                    form = validate(loggedIn ? 'change' : signIn ? 'signin' : 'signup');
                    if (!!form) return [3 /*break*/, 1];
                    toast('Please fix errors in form');
                    return [2 /*return*/];
                case 1:
                    if (!loggedIn) return [3 /*break*/, 3];
                    return [4 /*yield*/, changePassword(form)];
                case 2:
                    res = _a.sent();
                    if (res) {
                        toast('Successfully changed password');
                        reset();
                        setEmail('');
                        setChangePass(false);
                    }
                    else {
                        toast('Current password incorrect');
                        reset();
                    }
                    return [3 /*break*/, 9];
                case 3:
                    if (!signIn) return [3 /*break*/, 5];
                    return [4 /*yield*/, apiIn(form)];
                case 4:
                    res = _a.sent();
                    if (res.loggedIn) {
                        login(res);
                    }
                    else {
                        toast(res.message);
                        reset();
                    }
                    return [3 /*break*/, 9];
                case 5: return [4 /*yield*/, signUp(form)];
                case 6:
                    if (!_a.sent()) return [3 /*break*/, 8];
                    return [4 /*yield*/, apiIn(form)];
                case 7:
                    res = _a.sent();
                    if (res.loggedIn) {
                        login(res);
                    }
                    else {
                        toast('Account created but login failed');
                        setSignIn(true);
                        reset();
                    }
                    return [3 /*break*/, 9];
                case 8:
                    toast('Failed to create account');
                    reset();
                    _a.label = 9;
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var login = function (res) {
        setAuth(res);
        toast(res.message);
        setShow(false);
        reset();
        setEmail('');
        if (staySignedIn) {
            var str = JSON.stringify(res);
            window.localStorage.setItem('auth', str);
        }
    };
    var reset = function () {
        setOldPass('');
        setNewPass('');
        setConfirm('');
        setStaySignedIn(true);
    };
    var validate = function (type) {
        switch (type) {
            case 'signin':
                if (!newEmail || !newPass)
                    return null;
                return { email: newEmail, password: newPass };
            case 'signup':
                if (!newEmail || !newPass || !confirm || confirm !== newPass)
                    return null;
                return { email: newEmail, password: newPass };
            case 'change':
                if (!oldPass || !newPass || !confirm || confirm !== newPass)
                    return null;
                return { oldPass: oldPass, newPass: newPass, token: token };
        }
    };
    var loginForm = (React.createElement(Form, { className: 'formcard flex-v auth-form', onSubmit: handleSubmit },
        React.createElement(Form.Group, { hidden: loggedIn, className: 'mt-2' },
            React.createElement("input", { className: 'input', type: 'email', name: 'email', id: 'email', placeholder: 'Email', value: newEmail, onChange: function (e) { return setEmail(e.target.value); } })),
        React.createElement(Form.Group, { hidden: !loggedIn },
            React.createElement("input", { className: 'input mt-4', type: 'password', name: 'password', id: 'new-password', value: oldPass, onChange: function (e) { return setOldPass(e.target.value); }, placeholder: 'Current password' })),
        React.createElement(Form.Group, null,
            React.createElement("input", { className: 'input mt-4', type: 'password', name: 'password', id: 'old-password', value: newPass, onChange: function (e) { return setNewPass(e.target.value); }, placeholder: loggedIn ? 'New Password' : 'Password' })),
        loggedIn || (!loggedIn && !signIn) ? (React.createElement(Form.Group, { key: 'password-confirm' },
            React.createElement("input", { className: 'input mt-4', type: 'password', name: 'password-confirm', id: 'password-confirm', value: confirm, onChange: function (e) { return setConfirm(e.target.value); }, placeholder: 'Confirm password' }))) : null,
        React.createElement(Form.Group, { hidden: loggedIn, className: 'mt-2 ml-3' },
            React.createElement(Form.Label, null,
                React.createElement(Form.Check, { className: 'checkmark', type: 'checkbox', onChange: function () {
                        setStaySignedIn(!staySignedIn);
                    }, checked: staySignedIn }),
                ' ',
                "Stay signed in")),
        React.createElement(Form.Group, { className: 'toggle-btns mt-2' },
            React.createElement(Button, { hidden: loggedIn && !changePass, variant: 'outline-primary', type: 'submit', className: 'text-uppercase center-item', style: {
                    padding: '3px',
                    fontSize: '18px',
                    width: '45%',
                    marginRight: '0.2rem',
                } }, changePass
                ? 'Change Password'
                : signIn
                    ? 'Sign in'
                    : 'Register'),
            React.createElement(Button, { hidden: loggedIn, onClick: function (e) {
                    var btn = e.target;
                    btn.blur();
                    toggleAuth();
                }, variant: 'outline-secondary', className: 'text-uppercase center-item', style: {
                    padding: '3px',
                    fontSize: '18px',
                    width: '45%',
                    marginLeft: '0.2rem',
                } }, signIn ? 'Register' : 'Sign in'))));
    var authModal = (React.createElement(Modal, { keyboard: false, backdrop: true, show: show, onEntered: function () {
            addEventListener('click', onClick);
        } },
        React.createElement(CloseButton, { className: 'close-modal', onClick: closeButton }),
        React.createElement(Modal.Header, { className: 'account-title' },
            React.createElement(Modal.Title, { className: 'text-large' }, title)),
        React.createElement(Modal.Body, null,
            changePass ? loginForm : loggedIn ? null : loginForm,
            React.createElement("div", { className: 'flex' },
                React.createElement("div", { className: 'center-item' }, loggedIn ? (changePass ? (React.createElement(Button, { variant: 'outline-secondary', onClick: function (e) {
                        ;
                        e.target.blur();
                        setChangePass(false);
                    }, className: 'ml-3' }, "Cancel")) : (React.createElement(React.Fragment, null,
                    React.createElement(Button, { color: 'primary', variant: 'outline-primary', onClick: function (e) {
                            ;
                            e.target.blur();
                            setChangePass(true);
                        } }, "Change Password"),
                    React.createElement(Button, { variant: 'outline-danger', onClick: function (e) {
                            ;
                            e.target.blur();
                            signOut();
                        } }, "Sign Out")))) : null)))));
    return (React.createElement(Navbar, { sticky: 'top', className: 'nav' },
        React.createElement(Navbar.Brand, { className: 'text-white navbrand' },
            React.createElement("span", { className: 'mega' }, "LoL"),
            "Forge",
            ' ',
            React.createElement("span", { className: 'subhead' }, 'League of Legends Builds & Stats')),
        React.createElement("div", { className: 'login-account-btn' },
            loggedIn ? (React.createElement("span", { onClick: function () { return setShow(true); }, className: 'text-white text-large link' }, "Account")) : (React.createElement(Button, { className: 'text-white text-large', onClick: function () { return setShow(true); } }, "Login")),
            authModal)));
};
