var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Stat } from './stats';
var Item = /** @class */ (function (_super) {
    __extends(Item, _super);
    function Item(info, stats) {
        var _this = _super.call(this, stats || []) || this;
        _this.itemId = info.itemId;
        _this.itemName = info.itemName;
        _this.icon = info.icon;
        _this.hasStats = (stats !== undefined);
        _this.from = info.from;
        _this.into = info.into;
        if (stats) {
            _this.statsArray = stats;
        }
        return _this;
    }
    Item.prototype.addStats = function (stats) {
        var info = {
            itemId: this.itemId,
            itemName: this.itemName,
            icon: this.icon,
            from: this.from,
            into: this.into,
            statsArray: null
        };
        return new Item(info, stats);
    };
    return Item;
}(Stat));
export { Item };
