var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useContext } from "react";
import { Build } from "../../classes";
import { context as ctx } from "../../hooks";
import Select, { createFilter } from 'react-select';
import { LiRender } from "..";
import { Actions } from "../../declarations";
var search = createFilter({ ignoreCase: true, matchFrom: 'start' });
export var ChampSelector = function () {
    var _a, _b, _c;
    var _d = useState(null), champ = _d[0], setChamp = _d[1];
    var b = useContext(ctx.buildContext);
    var c = useContext(ctx.champContext);
    var a = useContext(ctx.authContext);
    var i = useContext(ctx.itemContext);
    // const selectChamp = (id: number) => {
    //     const newChamp = c.champs.find(champ => champ.champId === id)
    //     c.setSelectedChamp(newChamp)
    //     if (b.selected) {
    //         if (b.selected.champ.champId === newChamp.champId) return
    //         b.dispatch({type: Actions.ChangeChamp, newChamp })
    //     }
    // }
    var selectChamp = function (val) {
        setChamp(val);
        var selectedChamp = c.champs.find(function (c) { return c.champId === val.value; });
        if (!a.auth.loggedIn && !b.selected) {
            Build.fromChamp(selectedChamp, c, i).then(function (build) {
                b.dispatch({ type: Actions.Swap, build: build });
            });
        }
        else if (a.auth.loggedIn && b.selected && b.selected.champ.champId !== val.value) {
            b.dispatch({ type: Actions.ChangeChamp, newChamp: selectedChamp });
            c.setSelectedChamp(selectedChamp);
            if (a.auth.loggedIn) {
                b.selected.save(a.auth.token).then(function (r) {
                    b.dispatch({ type: Actions.Swap, build: r });
                });
            }
        }
        else if (!b.selected) {
            c.setSelectedChamp(selectedChamp);
        }
    };
    useEffect(function () {
        if (!b.selected)
            return;
        setChamp({
            value: b.selected.champ.champId,
            label: b.selected.champ.champName,
            isChamp: true,
        });
    }, [(_a = b === null || b === void 0 ? void 0 : b.selected) === null || _a === void 0 ? void 0 : _a.champ]);
    useEffect(function () {
        if (!b.selected)
            return;
    }, [(_b = b.selected) === null || _b === void 0 ? void 0 : _b.items]);
    useEffect(function () {
        if (c.selectedChamp)
            return;
        setChamp(null);
    }, [c.selectedChamp]);
    return (React.createElement("div", { className: 'selectors' },
        React.createElement(Select, { className: 'champ-select', blurInputOnSelect: true, value: champ, onChange: selectChamp, closeMenuOnScroll: false, formatOptionLabel: function (data) { return React.createElement(LiRender, __assign({}, data)); }, options: ((_c = c === null || c === void 0 ? void 0 : c.champs) === null || _c === void 0 ? void 0 : _c.length)
                ? c.champs.map(function (champ) { return ({
                    value: champ.champId,
                    label: champ.champName,
                    isChamp: true,
                }); })
                : null, filterOption: search, placeholder: 'Pick a champion' })));
};
