export var reducer = function (build, action) {
    if (!build) {
        if (action.type === 'SWAP') {
            return action.build;
        }
        else {
            throw new Error('No build');
        }
    }
    else {
        if (action.type === 'SWAP' && action.build === null)
            return null;
        var newBuild = build.reduce(action);
        if (action.type !== 'SWAP') {
            newBuild.saved = false;
        }
        return newBuild;
    }
};
