import React, { useContext, useEffect, useState } from "react";
import { context } from "../../hooks";
import { Slot, Tray } from "..";
var nullItem = null;
var slots = [0, 1, 2, 3, 4, 5];
export var OneBuild = function () {
    var token = useContext(context.authContext).auth.token;
    var _a = useContext(context.buildContext), selected = _a.selected, dispatch = _a.dispatch;
    var _b = useState(null), tray = _b[0], setTray = _b[1];
    useEffect(function () {
        // console.log('selected build:')
        // console.log(selected)
        if (!selected) {
            setTray(slots.map(function (i) { return React.createElement(Slot, { i: i, key: i }); }));
        }
        else {
            setTray(slots.map(function (i) {
                var _a;
                return (React.createElement(Slot, { item: (_a = selected === null || selected === void 0 ? void 0 : selected.items[i]) !== null && _a !== void 0 ? _a : null, key: i, i: i }));
            }));
        }
    }, [selected]);
    return (React.createElement(Tray, null, tray));
};
